
// options for each object are:
// type: video  :: type, mediaid, (image is optional if providing special image, by default image comes from mediaid)
// type: result :: type, image, name, location, testimony 
// type: quote  :: type, name, location, title, testimony

var imagePath = "/Images/";
var carouselObj = [{
    "type" : "video",
    "mediaid" : "UjgVmKff",
},{
    "type":"quote",
    "title":"Skin back to normal...",
    "testimony":"I had a rash on my shins halfway from my knee down to me ankle. The doctors gave me creams to put on them for years and I never was able to stop or cure the rash. It itched badly and I had to force myself to not scratch... I got my hands on Renew and within 1 week it was gone. It did take a few weeks for the skin to get back to normal, but I have not had a breakout of any kind since then.",
    "name":"Dennis",
    "location":"TX",
},{
    "type" : "video",
    "mediaid" : "M4i6AeLs",
},{
    "type":"quote",
    "title":"Happy to have found Renew...",
    "testimony":"My daughter was born with eczema. She would constantly scratch her skin especially the face. I tried using all kinds of creams and ointments but none of them helped. One day a coworker introduced me to Renew. I was reluctant but when I got home from work that day I slathered the lotion on her body. She didn't cry because it burned her skin or complain about being greasy. I'm happy to have found Renew lotion.",
    "name":"Judith",
    "location":"GA",
},{
    "type":"result",
    "image": imagePath + "renew-testimony-2-kim-va.jpg",
    "title":"Always suffered from eczema...",
    "testimony":"I have always suffered from eczema, especially on my hands. I started using Renew and within two weeks, saw a noticeable difference. Today, I am eczema free! I use Renew on my face with great results. I can't say enough about this product.",
    "name":"Kim",
    "location":"VA",
},{
    "type" : "video",
    "mediaid" : "J5wEFaDm",
},{
    "type" : "video",
    "mediaid" : "CHKKDm07",
},{
    "type" : "video",
    "mediaid" : "72QyKiIG",
},{
    "type" : "video",
    "mediaid" : "sdurOrwM",
},

{
    "type":"quote",
    "title":"Thank-you Renew!",
    "testimony":"Renew is great for taking care of my dry, itchy skin. I started applying Renew after showers...now the itch has receded to being hardly noticeable... Renew is a faithful addition to my family and routine now. Thank-you Renew!",
    "name":"Dale",
    "location":"WY",
},{
    "type" : "video",
    "mediaid" : "5AfqeosT",
},{
    "type" : "video",
    "mediaid" : "3RneHu1s",
},{
    "type":"result",
    "image": imagePath + "renew-testimony-3-maryjo-ca.jpg",
    "title":"Moisture locks in...",
    "testimony":"I use Renew lotion on my entire body after every shower. I love it because it the moisture locks in. It has a lovely fresh scent without being perfumed. I live where there is drought and dry conditions so I carry travel size Renew with me always. Renew lotion is a staple for me (like eggs and milk.) I need it and can't be without it.",
    "name":"MaryJo",
    "location":"CA",
},{
    "type":"result",
    "image": imagePath + "renew-testimony-5-shirley-al.jpg",
    "title":"Renewed life...",
    "testimony":"Renew has renewed life to my husband's skin. His chronic dry skin condition used to flare up in the drier periods of the year. Renew was able to change that.",
    "name":"Shirley",
    "location":"AL",
},{
    "type" : "video",
    "mediaid" : "7b2mkOeq",
},{
    "type":"quote",
    "title":"Fell in love with Renew...",
    "testimony":"I had very dry cracked hands that were painful and would bleed regularly. No amount of regular use of the best name brand lotions could relieve my dry hurting hands. I fell in love with Renew instantly. It was soothing, absorbed beautifully, left no greasy residue, and my hands were beginning to feel soft as the dryness and cracking seemed to go away... Renew is well worth the investment for the relief of pain and discomfort. No more bleeding hands.",
    "name":"Rhonda",
    "location":"WA",    
},{
    "type" : "video",
    "mediaid" : "Lcoj8xQK",
},{
    "type" : "video",
    "mediaid" : "RKmkQcLu",
},

{
    "type":"result",
    "image": imagePath + "renew-testimony-4-michelle-az.jpg",
    "title":"Baby's face...",
    "testimony":"This was my baby's face before bed and her face in the morning. The only thing I put on her was Renew.",
    "name":"Michele",
    "location":"AZ",
},{
    "type" : "video",
    "mediaid" : "emKNtoXH",
},{
    "type":"result",
    "image": imagePath + "renew-testimony-0-carrie.jpg",
    "testimony":"I received a third degree burn from cooking and ended up at the ER. I was in extreme pain and my body was in shock! After 1 week of cream and antibiotics the itch was unbearable. That's when I realized, I already had the perfect thing. My miracle lotion. What a difference! No more burning, stinging but instead pure relief. And the scar is minimal! Thanks Renew! You are true magic in a bottle.",
    "name":"Carrie",
    "location":"IL",
},{
    "type" : "video",
    "mediaid" : "GzpWbYni",
},{
    "type":"quote",
    "title":"Make sure to have plenty...",
    "testimony":"My son owns a painting business and is outside a lot as he works in all kinds of weather. He started using it and loves it. He even puts it on his face to keep from being chapped in the cold weather. I always make sure to have plenty.",
    "name":"Sandy",
    "location":"IN",
},{
    "type" : "video",
    "mediaid" : "cOAaKcUw",
},{
    "type":"result",
    "image": imagePath + "renew-testimony-1-cindy-tx.jpg",
    "testimony":"I suffered from an embarrassing skin condition in my ears and spent many days at a Dermatologist's Office trying several different ointments and creams. Nothing worked until I started using Renew. I used it every day for about two weeks. No more oozing, bloody, itchy, dry or scaly ears! Love Renew and can't live without it!",
    "name":"Cindy",
    "location":"TX",
},{
    "type" : "video",
    "mediaid" : "QbGuLHvE",
},{
    "type":"quote",
    "title":"Our go-to lotion...",
    "testimony":"I have had four members of my family work in the food industry with my daughter also going to beauty school. The soaps and chemicals that they use continually dries out their hands and leaves them cracking and painful. Renew lotion has become our go-to lotion and we have found that it works better than anything else on the market.",
    "name":"Daniel",
    "location":"UT",
}
];
